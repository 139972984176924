<template>
  <div class="custom-margin">
    <v-card>
      <v-card-title class="custom-title">急診聯繫單</v-card-title>
      <v-card-text>
        <v-form>
          <v-container>

            <!-- 第一層主標題（送醫前必要資訊、此次就醫、基本資料、身體評估） -->
            <v-row dense v-for="(content, key) in contents" :key="key">
              <v-col cols="12">
                <div class="level1"> {{ content.title }}</div>
              </v-col>
 
              <!-- 第二層標題 (description) -->
              <v-col cols="12">
                <v-row
                dense
                v-for="(section, contentIndex) in content.sections"
                :key="contentIndex"
                >
                  <v-col cols="12">
                    <div class="level2"> {{ section.description }}</div>
                  </v-col>
                  <v-row dense v-for="(question, questionIndex) in section.questions" :key="questionIndex">
                    <v-col cols="12">
                      <!-- 第三層之後，使用元件處理 -->
                      <EmergencyContactComponent
                        :question="question"
                      ></EmergencyContactComponent>
                    </v-col>
                  </v-row>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn class="mx-4" color="blue darken-1" text @click="submitForm"
          >送出</v-btn
        >
        <v-btn
          class="mx-4"
          color="red darken-1"
          text
          @click="clearAnswers"
          >清空</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import EmergencyContactComponent from "@/components/EmergencyContactComponent.vue"; // 引入剛創建的子組件
export default {
  name: "EmergencyContact",
  components: { EmergencyContactComponent },
  data() {
    return {
      headers: [],
      questionnaire: null,
      menu: false,
      selectedDate: new Date(),
      add_record_dialog: false,
      contents: {},
      view_date: "",
      window_width: "",
    };
  },
  methods: {
    async getQuestions() {
      const axios = require("axios");
      let config = {
        method: "get",
        url: `/api/journal/get_emergency_contact_question?name=EMER_001`,
        header: {},
      };
      try {
        let res = await axios.request(config);
        this.contents = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async makeRecord(case_num) {
      const axios = require("axios");
      let data = JSON.stringify({
        case_num: case_num,
        employee_id: this.staff_id,
        date: this.formattedDate,
        ...this.contents,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/journal/make_record",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      try {
        let res = await axios.request(config);
        console.log(`${res.data.status}, ${res.data.data}`);
        this.getList();
      } catch (error) {
        console.log(error);
      }
    },

    viewDailyRecord(item) {
      this.selected_case_num = item.case_num;
      this.selected_case_name = item.name;
      this.view_record_dialog = true;
      this.getRecordList(this.selected_case_num);
    },
    addRecord(item) {
      this.selected_case_name = item.name;
      this.case_num = item.case_num;
      this.getRecord(this.case_num, this.formattedDate);
      this.add_record_dialog = true;
    },
    getCurrentDate() {
      this.selectedDate = new Date();
    },
    submitForm() {
      this.makeRecord(this.case_num);
    },
    submitUpload() {
      console.log(this.imagePreviews);
    },
    formatDate(dateTimeStr) {
      const date = new Date(dateTimeStr);
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${month}/${day}`;
    },
    formatTime(dateTimeStr) {
      const date = new Date(dateTimeStr);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${hours}:${minutes}`;
    },
    clearAnswers(){
      this.getQuestions();
    }
  },
  computed: {
    formattedDate: {
      get() {
        const year = this.selectedDate.getFullYear();
        const month = this.selectedDate.getMonth() + 1;
        const day = this.selectedDate.getDate();
        return `${year}-${month.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}`;
      },
      set(newValue) {
        const [year, month, day] = newValue.split("-").map(Number);
        this.selectedDate = new Date(year, month - 1, day);
      },
    },
  },
  mounted() {
    this.getQuestions();
  },
  watch: {},
  created() {
    let staff = sessionStorage.getItem("staff");
    this.staff_id = JSON.parse(staff).id;
  },
};
</script>
<style scoped>

.custom-title {
  color: #0079ff;
  font-size: 32px;
}

.level1 {
  font-size: 24px;
  font-weight: bold;
  color: #336ca4;
  padding-bottom: 1.5rem;
}

.level2 {
  font-size: 20px;
  font-weight: bold;
  color: #34495e;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.custom-margin {
  overflow-x: auto !important;
  /* max-width: fit-content;。 */
}

.table-row {
  width: 75vw;
}

.table {
  width: 100%;
}

.selected-option {
  background-color: #0078ff !important;
  color: white !important;
}

.gallery-item {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ccc;
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  cursor: pointer;
}

.upload-placeholder {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-list {
  max-height: 600px;
  overflow-y: auto; /* 如果留言過多，添加滾動條 */
  max-width: 100%;
  padding-left: 1.5em;
  padding-right: 1.5em;
  font-size: 1.5em;
}

/* 機構員工的留言樣式 */
.staff-message {
  max-width: 60%;
  margin-left: 1;
  float: left;
}

/* 家屬的留言樣式 */
.family-message {
  max-width: 60%;
  margin-right: 1;
  float: right; /* 讓家屬的留言靠右對齊 */
}

/* 時間戳 */
.timestamp {
  font-size: 12px;
  color: #888; /* 灰色淺字 */
  margin-top: 5px;
}
</style>
