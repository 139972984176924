<template>
  <div class="level3">
    {{ question.question }}
    <span v-if="question.unit">({{ question.unit }})</span>
    <v-col cols="12">
      <div v-if="question.type === 'textfield'">
        <v-text-field dense v-model="question.answer"></v-text-field>

        <!-- 選擇後顯示子問題 -->
        <!-- 當 text-field 有輸入文字 且存在 subquestions 時顯示 subquestions -->
        <v-row v-if="question.answer && question.subquestions" class="mt-4">
          <v-col
            cols="12"
            v-for="(subquestion, subquestionIndex) in question.subquestions"
            :key="subquestionIndex"
          >
            {{ subquestion.question }}
            <div class="subquestion-title">
              <v-text-field
                v-if="subquestion.type === 'textfield'"
                v-model="subquestion.answer"
                dense
              ></v-text-field>
              <div v-else-if="subquestion.type === 'checkbox'">
                <v-btn-toggle v-model="subquestion.answer" dense style="flex-wrap: wrap">
                  <v-btn
                      v-for="option in subquestion.options"
                      :key="option"
                      :value="option"
                      color="#ffb200"
                      class="font-weight-bold"
                      active-class="selected-option"
                      >{{ option }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else-if="question.type === 'textarea'">
        <v-textarea v-model="question.answer" outlined dense></v-textarea>
      </div>
      <div v-else-if="question.type === 'checkbox'">
        <v-btn-toggle v-model="question.answer" dense style="flex-wrap: wrap">
          <v-btn
            v-for="option in question.options"
            :key="option"
            :value="option"
            color="#ffb200"
            class="font-weight-bold"
            active-class="selected-option"
            >{{ option }}</v-btn
          >
        </v-btn-toggle>
        <v-row v-if="question.answer && question.subquestions" class="mt-4">
          <v-col
            cols="12"
            v-for="(subquestion, subquestionIndex) in question.subquestions"
            :key="subquestionIndex"
          >
          {{  subquestion.question }}
            <v-menu
              v-if="subquestion.type === 'datetime'"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              absolute
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="subquestion.answer"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="subquestion.answer"
                no-title
                scrollable
                dense
                show-adjacent-months
                absolute
              >
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-else-if="subquestion.type === 'textfield'"
              v-model="subquestion.answer"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-else-if="question.type === 'switch'">
        <v-switch
          color="blue"
          class="mt-0"
          v-model="question.answer"
        ></v-switch>

        <!-- 選擇後顯示子問題 -->
        <!-- 當 switch 打開且存在 subquestions 時顯示 subquestions -->
        <v-row v-if="question.answer && question.subquestions" class="mt-4">
          <v-col
            cols="12"
            v-for="(subquestion, subquestionIndex) in question.subquestions"
            :key="subquestionIndex"
          >
            {{ subquestion.question }}
            <div class="subquestion-title">
              <v-text-field
                v-if="subquestion.type === 'textfield'"
                v-model="subquestion.answer"
                dense
              ></v-text-field>
              <v-textarea
                v-else-if="subquestion.type === 'textarea'"
                v-model="subquestion.answer"
                dense
              ></v-textarea>
              <v-switch
                v-else-if="subquestion.type === 'switch'"
                v-model="subquestion.answer"
              ></v-switch>
              <v-btn-toggle
                dense
                style="flex-wrap: wrap"
                multiple
                v-else-if="subquestion.type === 'multichoice'"
              >
                <v-btn
                  v-for="(
                    subquestionOption, subquestionOptionIndex
                  ) in subquestion.options"
                  :key="subquestionOptionIndex"
                  :value="subquestionOption"
                  color="#ffb200"
                  class="font-weight-bold"
                  active-class="selected-option"
                  >{{ subquestionOption }}</v-btn
                >
              </v-btn-toggle>
              <v-menu
                v-else-if="subquestion.type === 'date'"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="subquestion.answer"
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="subquestion.answer"
                  no-title
                  scrollable
                  dense
                  show-adjacent-months
                >
                </v-date-picker>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row v-else-if="question.type === 'multichoice'">
        <v-btn-toggle dense style="flex-wrap: wrap" multiple>
          <v-btn
            v-for="option in question.options"
            :key="option"
            :value="option"
            color="#ffb200"
            class="font-weight-bold"
            active-class="selected-option"
            >{{ option }}</v-btn
          >
        </v-btn-toggle>
      </v-row>
      <v-row v-else-if="question.type === 'date'">
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="question.answer"
              append-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="question.answer"
            no-title
            scrollable
            dense
            show-adjacent-months
            absolute
          ></v-date-picker>
        </v-menu>
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "EmergencyContactComponent",
  props: {
    question: Object,
  },
};
</script>

<style scoped>
.selected-option {
  background-color: #0078ff !important;
  color: white !important;
}

.textfield {
}
.level3 {
  font-size: 16px;
  font-weight: bold;
  color: #0b6100;
  padding-bottom: 0.5rem;
}
</style>
